import React, { Component } from "react";
import Breadcrumb from "../elements/common/Breadcrumb";
import { connect } from 'react-redux';
import appActions from '../redux/app/actions'
import { Link } from 'react-router-dom'
import { WaveTopBottomLoading } from 'react-loadingg';
import { formatName } from '../helpers/utility'
import { Redirect } from 'react-router-dom'
import config from '../../config/config';

const { apiConfig } = config
const { getStructure, setNewLevel, getStudents, resetUnmatchedLink, setHeaderDetails } = appActions

class Students extends Component{
    constructor(props) {
        super(props)
        this.state = {
            url: []
        }
    }
    componentDidMount() {
        this.props.getStructure(apiConfig.defaultFolder)
        const url = window.location.pathname.split('/')
        this.setState({url})
        window.scrollTo(0, 0)
    }
    
    componentDidUpdate(prevProps) {
        const { driveTree, currentLevel } = this.props
        if (driveTree.length && driveTree !== prevProps.driveTree) {
            this.props.setNewLevel()
        }
        if ((currentLevel !== prevProps.currentLevel) && currentLevel.id) {
            this.props.getStudents(currentLevel.id)
        }
    }
    render(){
        const { currentLevel, loading, unmatchedLink } = this.props
        const { url } = this.state
        const currentLocation = window.location.pathname
        const section = url[2]
        const year = url[1]

        if (unmatchedLink) {
            this.props.resetUnmatchedLink()
            return (<Redirect to='/'/>)
        } 
        return(
            <React.Fragment>
                {/* Start Breadcrump Area */}
                <Breadcrumb
                  title={currentLevel.name}
                  subtitle={section}
                  year={year}/>
            
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                  {loading ? <WaveTopBottomLoading color={'#F21249'} style={{margin: 'auto'}} /> : <div className="container">
                        <div className="row service-one-wrapper">
                            {this.props.students.map(student => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={student.id}>
                                    <Link to={`${currentLocation}/${formatName(student.name)}`} replace={false}>
                                        <div className="service service__style--2">
                                            <div className="content">
                                                <h3 className="title" style={{textAlign: 'center'}}>{student.name}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                  }
                </div>
                {/* End Service Area */}
            </React.Fragment>
        )
    }
}
function mapStateToProps (state) {
    return {
      loading: state.AppReducer.loading,
      driveTree: state.AppReducer.driveTree,
      newLevel: state.AppReducer.newLevel,
      students: state.AppReducer.students,
      currentLevel: state.AppReducer.currentLevel,
      unmatchedLink: state.AppReducer.unmatchedLink
    }
  }
  
  export default connect(
    mapStateToProps, {
      setNewLevel,
      getStudents,
      resetUnmatchedLink,
      setHeaderDetails,
      getStructure
    }
  )(Students)