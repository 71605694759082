const actions = {
    GET_STRUCTURE_REQUEST: 'GET_STRUCTURE_REQUEST',
    GET_STRUCTURE_ERROR: 'GET_STRUCTURE_ERROR',
    GET_STRUCTURE_SUCCESS: 'GET_STRUCTURE_SUCCESS',

    SET_NEW_LEVEL: 'SET_NEW_LEVEL',

    GET_STUDENTS_REQUEST: 'GET_STUDENTS_REQUEST',
    GET_STUDENTS_ERROR: 'GET_STUDENTS_ERROR',
    GET_STUDENTS_SUCCESS: 'GET_STUDENTS_SUCCESS',

    GET_STUDENT_REQUEST: 'GET_STUDENT_REQUEST',
    GET_STUDENT_ERROR: 'GET_STUDENT_ERROR',
    GET_STUDENT_SUCCESS: 'GET_STUDENT_SUCCESS',

    RESET_UNMATCHED_LINK : 'RESET_UNMATCHED_LINK',

    SET_UNMATCHED_LINK: 'SET_UNMATCHED_LINK',

    CLEAR_STUDENT: 'CLEAR_STUDENT',

    SET_HEADER_DETAILS: 'SET_HEADER_DETAILS',

    CLEAR_CURRENT_LEVEL: 'CLEAR_CURRENT_LEVEL',

    getStructure: (path) => ({
      type: actions.GET_STRUCTURE_REQUEST,
      path
    }),

    setNewLevel: () => ({
      type: actions.SET_NEW_LEVEL
    }),

    getStudents: (id) => ({
      type: actions.GET_STUDENTS_REQUEST,
      id
    }),

    getStudent: (id) => ({
      type: actions.GET_STUDENT_REQUEST,
      id
    }),

    resetUnmatchedLink: () => ({
      type: actions.RESET_UNMATCHED_LINK
    }),

    setUnmatchedLink: () => ({
      type: actions.SET_UNMATCHED_LINK
    }),

    clearStudent: () => ({
      type: actions.CLEAR_STUDENT
    }),

    setHeaderDetails: (classTitle, color) => ({
      type: actions.SET_HEADER_DETAILS,
      classTitle, 
      color
    }),

    clearCurrentLevel: () => ({
      type: actions.CLEAR_CURRENT_LEVEL,
    })
  }
  export default actions
  