import actions from './actions'
import { dashToName } from '../../helpers/utility'

const initState = {
  driveTree: [],
  firstLayer: [],
  secondLayer: [],
  thirdLayer: [],
  newLevel: [],
  currentLevel: [],
  students: [],
  student: [],
  studentName: null,
  loading: false,
  portfolioLoading: false,
  unmatchedLink: false,
  headerColor: 'default-color',
  headerClassTitle: 'header--transparent'
}

export default function (state = initState, action) {
  switch (action.type) {
    case actions.GET_STRUCTURE_REQUEST:
    case actions.GET_STUDENTS_REQUEST:
      return {
        ...state,
        loading: true,
        portfolioLoading: true,
      }
    case actions.GET_STUDENT_REQUEST:
      return {
        ...state,
        portfolioLoading: true
      }
  
    case actions.GET_STRUCTURE_ERROR:
    case actions.GET_STUDENTS_ERROR:
      return {
        ...state,
        loading: false
      }

    case actions.GET_STUDENT_ERROR:
      return {
        ...state,
        unmatchedLink: action.redirectToHome,
        portfolioLoading: false
      }
  
    case actions.GET_STRUCTURE_SUCCESS:
      const {firstLayer, secondLayer, thirdLayer, posterFolderContent} = action
      let newFirstLayer = [ ...firstLayer ]
      let newSecondLayer = [ ...secondLayer ]
      let newThirdLayer = [ ...thirdLayer ]
      newFirstLayer.map(layer => layer.children = [...newSecondLayer[newFirstLayer.indexOf(layer)]])
      newFirstLayer.map(year => year.children.map(level => level.children = [...newThirdLayer[newFirstLayer.indexOf(year)][year.children.indexOf(level)]]))

      return {
        ...state,
        loading: false,
        driveTree: newFirstLayer,
        newLevel: newFirstLayer,
        posterId: posterFolderContent ? (Array.isArray(posterFolderContent) ? posterFolderContent[0].id : posterFolderContent.id): null
      }

    case actions.SET_NEW_LEVEL:
      const urlData = window.location.pathname.split('/')
      let level = state.driveTree
      let newLevel 
      let currentLevel = []
      let unmatchedLink = false
      urlData.forEach(item => {
        if (item === "") return
        newLevel = level.filter(driveTreeItem => dashToName(driveTreeItem.name).toLowerCase() === dashToName(item).toLowerCase())
        if (newLevel.length) {
          level = newLevel[0].children
        }
      })
      currentLevel = (newLevel && newLevel[0]) || []
      if ((currentLevel.length === 0) && state.students.length === 0) {
        unmatchedLink = true
      }
      return {
        ...state,
        loading: false,
        currentLevel: currentLevel,
        unmatchedLink: unmatchedLink,
        newLevel: level
      }

    case actions.GET_STUDENTS_SUCCESS:
      let flag = false
      if (action.students.length === 0) {
        flag = true
      }
        return {
          ...state,
          loading: false,
          students: action.students,
          unmatchedLink: flag
        }
  
    case actions.GET_STUDENT_SUCCESS:
      let studentDetails = state.students.filter(student => student.id === action.studentId)
        return {
          ...state,
          portfolioLoading: false,
          student: action.student,
          studentName: studentDetails[0].name
        }

    case actions.RESET_UNMATCHED_LINK: 
        return {
          ...state,
          unmatchedLink: false
        }

    case actions.SET_UNMATCHED_LINK: 
        return {
          ...state,
          unmatchedLink: true
        }

    case actions.CLEAR_STUDENT: 
      return {
        ...state,
        student: []
      }

    case actions.SET_HEADER_DETAILS:
      return {
        ...state,
        headerClassTitle: action.classTitle,
        headerColor: action.color
      }

    case actions.CLEAR_CURRENT_LEVEL: 
    return {
        ...state,
        currentLevel: []
      }

    default:
      return state
  }
}
