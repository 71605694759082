import axios from 'axios'
import config from '../../config/config'

const { apiConfig } = config


class DriveApiService {  
  getLayer (path) {
    return axios.get(`https://www.googleapis.com/drive/v3/files?q=%27${path}%27+in+parents&key=${apiConfig.apiKey}`)
      .then(result => result.data.files.map(layer => {
        return {
          id: layer.id,
          name: layer.name,
          children: []
        }
      }))
  }
}

export default new DriveApiService()
