import React, { Component , Fragment } from "react";
import { Redirect } from 'react-router-dom';
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from '../elements/service/ServiceTwo';
import About from "../component/HomeLayout/homeOne/About";
import appActions from '../redux/app/actions'
import { connect } from 'react-redux';
import config from '../../config/config';

const virtualGallery = [
    {
        title: 'Pictură',
        link: 'https://artspaces.kunstmatrix.com/en/node/1923989/'
    },
    {
        title: 'Sculptură',
        link: 'https://artspaces.kunstmatrix.com/en/node/2746597/'
    },
    {
        title: 'Grafică + Bandă desenată și desen animat',
        link: 'https://artspaces.kunstmatrix.com/en/node/2746600/'
    },
    {
        title: 'Foto-Video P.C.I.',
        link: 'https://artspaces.kunstmatrix.com/en/node/2746601/'
    },
    {
        title: 'Conservare-Restaurare',
        link: 'https://artspaces.kunstmatrix.com/en/node/2746607/'
    },
    {
        title: 'Ceramică-Sticlă-Metal',
        link: 'https://artspaces.kunstmatrix.com/en/node/2827480/'
    },
    {
        title: 'Arte textile - Design textil',
        link: 'https://artspaces.kunstmatrix.com/en/node/2746614/'
    },
    {
        title: 'Modă-Design vestimentar',
        link: 'https://artspaces.kunstmatrix.com/en/node/2787570/'
    },
    {
        title: 'Design',
        link: 'https://artspaces.kunstmatrix.com/en/node/2816617/'
    },
]

const { getStructure, setNewLevel, resetUnmatchedLink, setHeaderDetails } = appActions
const { apiConfig } = config

class MainPage extends Component{
    constructor(props) {
      super(props)
      this.state = {
        availableOptions: [],
        level: null,
        url: []
      }
    }
    componentDidMount() {
        this.props.getStructure(apiConfig.defaultFolder)
        const url = window.location.pathname.split('/')
        this.setState({url})
        this.props.setHeaderDetails('header--transparent-2', 'default-color')
    }
    
    componentDidUpdate(prevProps) {
        const { driveTree } = this.props
        if (driveTree.length && driveTree !== prevProps.driveTree) {
            this.setState({availableOptions: driveTree})
            this.props.setNewLevel()
        }
    }
    listenForChanges = () => {
        this.props.history.listen(() => {
            this.props.setNewLevel()
          });  
    }
    renderVirtualGallery = () => (         
        <div className="service-area ptb--80  bg_image bg_image--3">
            <div className="container">
            <div className="row">
                <div className="row">
                <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                    <h2 className="title" style={{marginBottom: 100}}>Galerie virtuală</h2>
                </div>
                </div>
                <div className="row">
                    {virtualGallery.map( (val , i) => (
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <a href={val.link} target="_blank" rel="noopener noreferrer" >
                                <div className="service service__style--2">
                                    <div className="content">
                                        <h3 className="title" style={{textAlign: 'center'}}>{val.title.toUpperCase()}</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>      
            </div>
            </div>
        </div>
    )
    
    render(){
        this.listenForChanges()
        const { unmatchedLink, loading } = this.props
        if (unmatchedLink) {
            this.props.resetUnmatchedLink()
            return (<Redirect to='/'/>)
        } 
        return(
            <Fragment> 
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area   */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <About posterId={this.props.posterId} posterLoading={loading}/>
                </div>
                {/* End About Area */}

                {/* {this.renderVirtualGallery()} */}

                <div className="service-area ptb--80">
                   <div className="container">
                        <ServiceTwo {...this.props}/>
                   </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps (state) {
    return {
      loading: state.AppReducer.loading,
      driveTree: state.AppReducer.driveTree,
      newLevel: state.AppReducer.newLevel,
      unmatchedLink: state.AppReducer.unmatchedLink,
      posterId: state.AppReducer.posterId
    }
  }
  
  export default connect(
    mapStateToProps, {
      setNewLevel,
      resetUnmatchedLink,
      setHeaderDetails,
      getStructure
    }
  )(MainPage)