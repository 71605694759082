import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { store, history } from './redux/store'
import {PublicRoutes} from './router'
const Root = () => (
            <Provider store={store}>
              <PublicRoutes history={history} />
            </Provider>
  )
Root()

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();