import React, {Component} from 'react'
import TextLoop from "react-text-loop";
import { connect } from 'react-redux';
import appActions from '../redux/app/actions'
import config from '../../config/config';
import Lightbox from 'react-image-lightbox';
import { dashToName } from '../helpers/utility'
import { WaveTopBottomLoading } from 'react-loadingg';
import 'react-image-lightbox/style.css';
import { Redirect } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const { getStructure, setNewLevel, getStudent,getStudents, setUnmatchedLink, resetUnmatchedLink, clearStudent, setHeaderDetails } = appActions
const { apiConfig } = config

class StudentPortfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            index: 0,
            url: []
        };
    }
    componentDidMount() {
        this.props.getStructure(apiConfig.defaultFolder)
        const url = window.location.pathname.split('/')
        this.setState({url})
        this.props.setHeaderDetails('header--transparent', 'black')
        window.scrollTo(0, 0)
    }

    componentWillUnmount () {
        this.props.clearStudent()
    }

    getSectionLevelId = () => {
        const { url } = this.state
        const year = this.props.driveTree.filter(yearObj => yearObj.name.toLowerCase() === url[1].toLowerCase())
        const level = year[0].children.filter(levelObj => levelObj.name.toLowerCase() === url[2].toLowerCase())
        const section = level[0].children.filter(sectionObj => dashToName(sectionObj.name).toLowerCase() === dashToName(url[3]).toLowerCase())
        return section[0]?.id
    }

    findIdOfStudent = () => {
        const { url } = this.state
        let student = this.props.students.filter(student => dashToName(student.name).toLowerCase() === dashToName(url[4]).toLowerCase())
        let id = null
        if (student[0] && student[0].id) {
            id = student[0].id
        }
        return id
    }
    
    componentDidUpdate(prevProps) {
        const { driveTree, students } = this.props
        if (driveTree.length && driveTree !== prevProps.driveTree) {
            let sectionLevelId = this.getSectionLevelId()
            if (sectionLevelId) {
                this.props.getStudents(sectionLevelId)
            } else {
                this.props.setUnmatchedLink()
            }
        }
        if ((prevProps.students !== students) && students.length) {
            let studentId = this.findIdOfStudent() 
            this.props.getStudent(studentId)
        }

    }

    removeExtension = (name) => {
        let splitName = name.split('.')
        let removedExtensionsArray = splitName.slice(0, splitName.length - 1 )
        let newName = ''
        removedExtensionsArray.forEach(element => {
            if (newName !== '') {
                newName = newName + '. ' + element
            } else {
                newName = element
            }
        })
        return newName
    }
    render () {
        const { isOpen, index, url } = this.state;
        const { student, portfolioLoading, unmatchedLink } = this.props
        if (unmatchedLink) {
            this.props.resetUnmatchedLink()
            return (<Redirect to='/'/>)
        }
        return (
            <div onContextMenu={(e)=> e.preventDefault()}>  
                    <div className="slider-wrapper">
                        <div className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--5 rn-slider-height">
                            <div className="container">
                              {portfolioLoading ? <WaveTopBottomLoading color={'#F21249'} style={{margin: '180px auto'}} /> : 
                                <div className="row align-items-center">
                                    <div className="col-lg-5">
                                        <div className="designer-thumbnail">
                                        {student[0] && student[0].id &&  
                                          <LazyLoadImage
                                            alt='profile'
                                            className="thumbnailImg"
                                            effect="blur"
                                            style={{objectFit: 'cover'}}
                                            src={`https://drive.google.com/uc?id=${student[0].id}`}
                                          />}
                                        </div>
                                    </div>
                                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                                        <div className={`inner text-left`}>
                                            <h1 className="title">{this.props.studentName} <br/>
                                            <TextLoop>
                                                <span style={{fontSize: 30}}>{url.length && url[2].toUpperCase()}</span>
                                                <span style={{fontSize: 30}}> {url.length && url[3].toUpperCase()}</span>
                                            </TextLoop>{" "}
                                            </h1>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                
                    {/* Start Designer Portfolio  */}
                    <div className="designer-portfolio-area ptb--120 bg_color--1">
                        <div className="wrapper plr--70 plr_sm--30 plr_md--30">
                            <div className="row row--35">
                            { student.map((artwork, i) => {
                                return (
                                    <div className={`col-lg-4 col-md-6 col-sm-6 col-12`} key={artwork.id}>
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={`https://drive.google.com/uc?id=${student[index].id}`}
                                            nextSrc={`https://drive.google.com/uc?id=${student[(index + 1) % student.length].id}`}
                                            prevSrc={`https://drive.google.com/uc?id=${student[(index + student.length - 1) % student.length].id}`}
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                            onMovePrevRequest={() =>
                                            this.setState({
                                                index: (index + student.length - 1) % student.length,
                                            })
                                            }
                                            onMoveNextRequest={() =>
                                                this.setState({
                                                    index: (index + 1) % student.length,
                                                })
                                            }
                                            imageLoadErrorMessage = 'Se încarcă ...'
                                            enableZoom={false}
                                        />
                                    )}
                                    <div className="item-portfolio-static">
                                        <div onClick={() => this.setState({ isOpen: true, index: i })}>
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <LazyLoadImage
                                                            alt='portfolio'
                                                            effect="blur"
                                                            src={`https://drive.google.com/uc?id=${artwork.id}`}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                        <h4>{this.removeExtension(artwork.name)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                            </div>
                        </div>
                    </div>
                    {/* End Designer Portfolio  */}
            </div>
        )
    }
}

function mapStateToProps (state) {
    return {
      portfolioLoading: state.AppReducer.portfolioLoading,
      driveTree: state.AppReducer.driveTree,
      newLevel: state.AppReducer.newLevel,
      student: state.AppReducer.student,
      students: state.AppReducer.students,
      studentName: state.AppReducer.studentName,
      currentLevel: state.AppReducer.currentLevel,
      unmatchedLink: state.AppReducer.unmatchedLink
    }
  }
  
  export default connect(
    mapStateToProps, {
      setNewLevel,
      getStudent,
      getStudents,
      setUnmatchedLink,
      resetUnmatchedLink,
      clearStudent,
      setHeaderDetails,
      getStructure
    }
  )(StudentPortfolio)
