import React, { Component } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const aboutParagraphs = [
    'Expoziția absolvenților UAD este unul din evenimentele de tradiție ale Universității de Artă și Design din Cluj-Napoca, ajuns la cea de a XXII-a ediție, și reprezintă cea mai așteptată expoziție anuală din mediul artistic universitar național. Manifestarea pune în lumină tineri absolvenți ale căror creații sintetizează educația și experiența profesională dobândită în cei 3 sau 5 ani de studii. Evenimentul, repetat anual, definește caracterul și individualitatea fiecărei generații de artiști absolvenți.',
    'Desfășurat fără încetare începând cu anul 2000 în locații generoase care adunau peste 3000 de lucrări ale absolvenților, ultimele două ediții au fost adaptate la evoluția situației epidemiologice și s-au integrat astfel trendului actual prin care, arta și cultura a trecut în mediul virtual / online ca prim mediu de interacțiune. În acest an, evenimentul revine în formatul fizic consacrat într-o nouă locație - Centrul Regional de Excelență pentru Industrii Creative (CREIC), într-o ediție care va aduce absolvenți ai promoției 2022 (nivel licență și masterat) de la Facultatea de Arte Plastice, departamentele Pictură, Sculptură, Grafică, Bandă desenată, Foto-video, Conservare-restaurare, Pedagogia artelor plastice și decorative, respectiv Facultatea de Arte Decorative și Design, departamentele Ceramică-sticlă, Arte textile-design textil, Modă-design vestimentar și Design.',
    'Proiectul Platforma de lansare a studenților și absolvenților Universității de Artă și Design Cluj-Napoca este susținut de Primăria şi Consiliului Local Cluj-Napoca, sprijinul venit din partea autorităţilor locale fiind esențial în prezentarea lucrărilor absolvenților și în acest an către publicul larg.'
]
class About extends Component{
    render(){
        const title = 'Despre'
        const {posterId, posterLoading} = this.props
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35">

                            <div className="col-lg-5 col-md-12 customHeight">
                                <div className="thumbnail">
                                    {!posterLoading && 
                                        <>
                                            {posterId ? 
                                                <LazyLoadImage
                                                    alt='profile'
                                                    className="poster"
                                                    effect="blur"
                                                    src={`https://drive.google.com/uc?id=${posterId}`}
                                                /> : 
                                                <img className="w-100" src="/assets/images/poster2022.jpg" alt="poster"/>
                                            }
                                        </>
                                    }
                              
                                </div>
                            </div>
                      
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 style={{margin: '50px 0'}} className="title">{title}</h2>
                                        {aboutParagraphs.map((text, i) => <p key={i} style={{fontSize: 15}} className="description">{text}</p>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;