import React, { Component, Fragment } from 'react'
import './index.scss'
import MainPage from './Containers/MainPage';
import Students from './Containers/Students';
import StudentPortfolio from './Containers/StudentPortfolio';
import error404 from "./elements/error404";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import Helmet from "./component/common/Helmet";
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { WaveTopBottomLoading } from 'react-loadingg';

export class App extends Component {
    render () {
        const { loading } = this.props
        return (
            <Fragment>
              <Helmet pageTitle="Expoziția studenților UAD" />
              <Header logoname="uad-logo-transparent.png" {...this.props}/>
              {loading ? <WaveTopBottomLoading color={'#F21249'} style={{margin: '180px auto'}} /> :        
              <Router>
              <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainPage}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/:year`} component={MainPage}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/:year/:level`} component={MainPage}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/:year/:level/:section`} component={Students}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/:year/:level/:section/:student`} component={StudentPortfolio}/>
                    
                    <Route exact path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
             </Router>}

                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
              <Footer />
            </Fragment>
         )
    } 
}

export default App