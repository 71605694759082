import { all, put, takeEvery, call, fork } from 'redux-saga/effects'
import actions from './actions'
import driveApiService from '../../services/driveApiService'
import { POSTER_FOLDER_NAME } from '../../constants'

function * getStructure () {
  yield takeEvery(actions.GET_STRUCTURE_REQUEST, function * ({path}) {
    try {
      const firstLayer = yield call(driveApiService.getLayer, path)
      const filteredFirstLayer = firstLayer.filter(layer => layer.name !== POSTER_FOLDER_NAME)
      const posterLayer = firstLayer.find(layer => layer.name === POSTER_FOLDER_NAME)
      const secondLayer = yield all (filteredFirstLayer.map(layer => call (driveApiService.getLayer, layer.id)))
      const posterFolderContent = posterLayer ? yield call(driveApiService.getLayer, posterLayer.id) : null
      const thirdLayer = yield all (secondLayer.map(layers => all (layers.map(layer => call (driveApiService.getLayer, layer.id)))))

      yield put({
        type: actions.GET_STRUCTURE_SUCCESS,
        result: filteredFirstLayer,
        firstLayer: filteredFirstLayer,
        secondLayer,
        thirdLayer,
        posterFolderContent 
      })
    } catch (error) {
      yield put({type: actions.GET_STRUCTURE_ERROR})
    }
  })
}

function * getStudents () {
  yield takeEvery(actions.GET_STUDENTS_REQUEST, function * ({id}) {
    try {
      const result = yield call(driveApiService.getLayer, id)
      yield put({
        type: actions.GET_STUDENTS_SUCCESS,
        students: result,
      })
    } catch (error) {
      yield put({type: actions.GET_STUDENTS_ERROR})
    }
  })
}

function * getStudent () {
  yield takeEvery(actions.GET_STUDENT_REQUEST, function * ({id}) {
    try {
      const result = yield call(driveApiService.getLayer, id)
      yield put({
        type: actions.GET_STUDENT_SUCCESS,
        studentId: id,
        student: result,
      })
    } catch (error) {
      yield put({type: actions.GET_STUDENT_ERROR, redirectToHome: true})
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getStructure),
    fork(getStudents),
    fork(getStudent)
  ])
}
