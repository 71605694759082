import React, { Component } from "react";
import appActions from '../../redux/app/actions'
import { connect } from 'react-redux';
import { formatName} from '../../helpers/utility'

const { setNewLevel } = appActions
class ServiceTwo extends Component{
    handleOnClick = (level) => {
        const { history } = this.props
        const original = history.location.pathname
        if (original !== '/') {
            history.push(`${original}/${formatName(level.name).toLowerCase()}`)
        } else {
            history.push(`/${formatName(level.name).toLowerCase()}`)
        }
        this.props.setNewLevel()
    }
    render(){
        let title = 'Lucrările absolvenților';
        const { newLevel } = this.props
        return(
            <React.Fragment>
                  <div className="row">
                    <div className="col-lg-4 col-24">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-md-center" style={{marginTop: 80}}>
                            {newLevel.map(level => (
                                <div className="col-lg-4 col-md-6 col-12" key={level.id}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a>
                                                <div style={{ height: 340, backgroundImage: `url("/assets/images/pattern-4.png")`}}>
                                                    <h2 style={{lineHeight: '45px', position: 'absolute', width: '100%', textAlign: 'center', fontSize: '35px', top: '30%'}}>{level.name}</h2>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="blog-btn">
                                                <button className="rn-btn text-white" onClick={() => this.handleOnClick(level)}>Vezi colectia</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
            </React.Fragment>
        )
    }
}
function mapStateToProps (state) {
    return {
      loading: state.AppReducer.loading,
      driveTree: state.AppReducer.driveTree,
      newLevel: state.AppReducer.newLevel
    }
  }
  
  export default connect(
    mapStateToProps, {
     setNewLevel
    }
  )(ServiceTwo)