export function formatName (name) {
  const spaceEncodedReplacement = name.replace("%20", "-");
  const spaceReplacement = spaceEncodedReplacement.replace(/ /g, "-");
  return spaceReplacement
}

export function dashToName (name) {
  const replacement = name.replace(/-/g, " ");
  return replacement
}

