import React from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { history } from './redux/store'
import App from './App'

const MainRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
        {...rest}
        render={props => <Component {...props} />}
      />
    )
}
export const PublicRoutes = () => (
  <Router history={history}>
        <MainRoute
            path='/'
            component={App}
          />
    </Router>
)

