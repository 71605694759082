import React, { Component } from "react";
import { connect } from 'react-redux';

class Header extends Component{
    render(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        const { headerClassTitle, headerColor } = this.props
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        let logoUrl;
        logoUrl = <img style={{width: 125}} src="/assets/images/uad-logo-transparent.png" alt="Digital Agency" />;
        return(
            <header className={`header-area formobile-menu ${headerClassTitle}`} style={{background: headerColor}}>
                <div className="header-wrapper" id="header-wrapper" >
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                              <a style={{color: 'white'}} href="/">Acasă</a>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        )
    }
}


function mapStateToProps (state) {
    return {
      headerClassTitle: state.AppReducer.headerClassTitle,
      headerColor: state.AppReducer.headerColor
    }
  }
  
  export default connect(
    mapStateToProps, {}
  )(Header)