import React, { Component } from "react";
import {FaGlobe ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/uadcluj'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/school/universitatea-de-art%C4%83-%C8%99i-design-din-cluj-napoca/'},
    {Social: <FaInstagram /> , link: 'http://instagram.com/uadcluj'},
    {Social: <FaGlobe /> , link: 'http://uad.ro/'},
]
const currentDate = new Date()
const baseInfo = ['Universitatea de Artă și Design din Cluj-Napoca', 'Piața Unirii 31, 400098, Cluj-Napoca' , 'Compartimentul de Relații Publice, Internaționale, Proiecte Culturale și Comunitare']
class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Do This</span>
                                        <h2>Let's get <br /> to work</h2>
                                        <a className="rn-button-style--2" href="/contact">
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-lg">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Contact</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:public@uad.ro">public@uad.ro</a></li>
                                                    <li style={{color: '#c6c9d8', opacity: 0.75, letterSpacing: '1.2px', marginTop: 0, marginBottom: 50}}>0264/595.021</li>
                                                </ul>
                                                {baseInfo.map((text, i) => <p key={i} style={{color: '#c6c9d8', opacity: 0.75, fontSize: 12, lineHeight: '12px'}}>{text}</p>)}
                                                <div className="social-share-inner" style={{marginTop: 30}}>
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`} target="_blank" rel="noopener noreferrer">{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>© {currentDate.getFullYear()} Expoziția absolvenților UAD. Toate drepturile rezervate.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;